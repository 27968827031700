import { useQuery, useMutation } from "@tanstack/react-query";
import * as shopService from "services/shop.service";
import shopStore from "common/stores/shop.store";
import userStore from "common/stores/user.store";
import _ from "lodash";

export const useShopQuery = () => {
  const setShops = shopStore((e) => e.setShops);
  const isAuthenticated = userStore((e) => e.isAuthenticated);

  return useQuery({
    queryKey: ["shopByUser"],
    queryFn: () => shopService.getShopByUser(),
    enabled: isAuthenticated,
    onSuccess: (shops) => {
      if (!_.isEmpty(shops)) {
        setShops(shops);
      }
    },
  });
};

export const useMutateShop = () => {
  const setShops = shopStore((e) => e.setShops);

  return useMutation({
    mutationKey: "shopByUser",
    mutationFn: () => shopService.getShopByUser(),
    onSuccess: (shops) => {
      if (!_.isEmpty(shops)) {
        setShops(shops);
      }
    },
  });
};

export const usePrimaryShopQuery = () => {
  const setPrimaryShop = shopStore((e) => e.setPrimaryShop);
  const isAuthenticated = userStore((e) => e.isAuthenticated);

  return useQuery({
    queryKey: ["primaryShop"],
    queryFn: () => shopService.getPrimaryShop(),
    enabled: isAuthenticated,
    onSuccess: (primaryShop) => {
      if (!_.isEmpty(primaryShop)) {
        setPrimaryShop(primaryShop);
      }
    },
    onError: (err) => {
      console.log("primary shop error:", err);
    },
  });
};

export const useMutatePrimaryShop = () => {
  const setPrimaryShop = shopStore((e) => e.setPrimaryShop);

  return useMutation({
    mutationKey: "primaryShop",
    mutationFn: () => shopService.getPrimaryShop(),
    onSuccess: (primaryShop) => {
      if (!_.isEmpty(primaryShop)) {
        setPrimaryShop(primaryShop);
      }
    },
    onError: (err) => {
      console.log("primary shop error:", err);
    },
  });
};

export const useShopInfoQuery = (shop_id) => {
  const setShop = shopStore((e) => e.setShop);
  const isAuthenticated = userStore((e) => e.isAuthenticated);

  return useQuery({
    queryKey: ["shopById", shop_id],
    queryFn: () => shopService.getShopInfo(shop_id),
    enabled: isAuthenticated && !!shop_id,
    onSuccess: (shop) => {
      if (!_.isEmpty(shop)) {
        setShop(shop);
      }
    },
    cacheTime: 0,
  });
};

export const useBusinessType = () => {
  return useQuery({
    queryKey: ["businessType"],
    queryFn: () => shopService.getBusinessType(),
  });
};
