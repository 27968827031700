const theme = {
  title: "CP Seeding",
  name: "CP SEEDING SOCIAL IMPACT",
  year: 2022,
  icon: "/icon/seeding.svg",
  logo: "/logo/seeding-label.svg",
  square_logo: "/logo/seeding-logo.svg",
  transparent_logo: "/logo/transparent-seeding.svg",
  avatar: "/avatar/seeding.png",
  "t&c":
    "https://storage.googleapis.com/storage.lotuss.originsme.com/consent-form/CPSSE/CPSSE_T_C_V02.pdf",
  "t&c_html":
    "https://storage.googleapis.com/storage.lotuss.originsme.com/consent-form/CPSSE/CPSSE_T_C_V02.html",
  privacy:
    "https://storage.googleapis.com/storage.lotuss.originsme.com/consent-form/CPSSE/CPSSE_Privacy Notice_V2.pdf",
  consent_html:
    "https://storage.googleapis.com/storage.lotuss.originsme.com/consent-form/CPSSE/CPSSE_Consent_V03.html",
  portal: true,
  modules: {
    profile: true,
    introduce: true,
    recruit: false,
    space: false,
    campaign: false,
    business_matching: true,
    policy: true,
  },
};

export default theme;
