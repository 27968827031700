import theme from "common/styles/ci";
import { useMutation } from "@tanstack/react-query";
import { updateUserInfo } from "services/user.service";
import SeedingModalTemplate from "./template";

export default function SeedingConsentModal(props) {
  const { mutate } = useMutation({
    mutationKey: ["seedingConsentModal"],
    mutationFn: (accept) =>
      updateUserInfo({
        allow_disclosure_agreement: accept,
      }),
  });

  function handleClick(accept) {
    mutate(accept);
    props.onClick();
  }

  return (
    <SeedingModalTemplate
      title="นโยบายคุ้มครองข้อมูลส่วนบุคคล"
      body={theme["consent_html"]}
      onClick={handleClick}
      choice
    />
  );
}
