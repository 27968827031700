/* eslint-disable react-hooks/rules-of-hooks */
import useMediaQuery from "@mui/material/useMediaQuery";

export const isMobileQuery = (props) => {
  return useMediaQuery((theme) => theme.breakpoints.down("sm"));
};

export const isTabletQuery = (props) => {
  return useMediaQuery((theme) => theme.breakpoints.up("sm"));
};

export const isLaptopQuery = (props) => {
  return useMediaQuery((theme) => theme.breakpoints.up("md"));
};

export const isDesktopQuery = (props) => {
  return useMediaQuery((theme) => theme.breakpoints.up("lg"));
};
