import { Box, Typography } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export default function Body(props) {
  const isUrl = props?.text?.includes("html");

  const { data, isLoading } = useQuery({
    queryKey: ["getT&cContent", props.text],
    queryFn: () => axios.get(props.text),
    enabled: isUrl,
    select: (res) => res.data,
  });

  return (
    <Box
      mx="auto"
      height={{
        xs: `calc(100vh - ${props.height})`,
        md: `calc(80vh - ${props.height})`,
      }}
    >
      <Box
        sx={{
          height: "inherit",
          bgcolor: props.bgcolor ?? "white",
          borderRadius: 3,
          p: 3,
          overflowX: "hidden",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            height: 5,
            width: 7,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "lightgrey",
            borderRadius: 2,
          },
        }}
      >
        {!props.disabledTitle && (
          <Typography
            fontWeight="bold"
            textAlign="center"
            sx={{
              fontSize: {
                xs: 20,
                sm: 30,
              },
              display: "inherit",
            }}
          >
            {props.title ?? "ข้อกำหนดและเงื่อนไข"}
            <br />
          </Typography>
        )}
        <Typography fontSize={{ xs: 14, sm: 16 }} sx={props.textProps}>
          {ReactHtmlParser(isUrl ? data : props.text)}
        </Typography>
      </Box>
    </Box>
  );
}
