import themeStore from "common/stores/theme.store";
import { useMemo } from "react";
import { Navigate } from "react-router";

function getDefaultRoute(modules) {
  if (modules?.recruit) {
    return "/recruit";
  } else if (modules?.space) {
    return "/space";
  } else if (modules?.business_matching) {
    return "/";
  } else {
    return "/auth/login";
  }
}

export default function DefaultPage() {
  const { modules } = themeStore((e) => e.theme);
  const defaultRoute = useMemo(() => getDefaultRoute(modules), [modules]);

  return <Navigate to={defaultRoute} />;
}
