import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Toolbar,
  Drawer,
  Modal,
  List,
  ListItem,
  IconButton,
  Divider,
  ListItemText,
  Dialog,
} from "@mui/material";
import { ChevronRight, Close } from "@mui/icons-material";
import { InformationBox } from "modules/space/booking/id/preview/information";
import ConfirmModal from "./confirm.modal";
import { isLaptopQuery } from "common/utils/mediaQuery";
import { useModal } from "mui-modal-provider";

export default function ReponsiveCancelBooking(props) {
  const { showModal } = useModal();
  const isMatch = isLaptopQuery();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    textAlign: "center",
    borderRadius: 3,
  };

  function handleClick(option) {
    props.onClose();
    const confirmModal = showModal(ConfirmModal, {
      option,
      onClose: () => confirmModal.hide(),
    });
  }

  return isMatch ? (
    <Modal {...props}>
      <Box sx={style}>
        <Content onClose={props.onClose} handleClick={handleClick} />
      </Box>
    </Modal>
  ) : (
    <Drawer anchor="bottom" {...props}>
      <Content onClose={props.onClose} handleClick={handleClick} />
    </Drawer>
  );
}

function Content(props) {
  const cancelOption = [
    { text: "ไม่สามารถไปพื้นที่ในวันที่จองได้", key: "unavailable" },
    { text: "ต้องการเปลี่ยนวันที่จอง", key: "change_date" },
    { text: "ต้องการเปลี่ยนพื้นที่ที่จอง", key: "change_space" },
    { text: "ไม่สามารถติดต่อเจ้าของพื้นที่ได้", key: "cannot_contact" },
    { text: "พบเจอข้อเสนออื่นที่ดีกว่า", key: "found_other_offer" },
    { text: "อื่นๆ (โปรดระบุ)", key: "other" },
  ];

  return (
    <Box
      role="presentation"
      // sx={{
      //   minWidth: { xs: "-webkit-fill-available", sm: "600px" },
      //   maxWidth: "600px",
      //   mx: "auto",
      // }}
    >
      <Toolbar sx={{ position: "relative", justifyContent: "center" }}>
        <Typography fontSize={18} fontWeight="bold">
          ยกเลิกการจอง
        </Typography>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={props.onClose}
          color="inherit"
          sx={{ position: "absolute", right: "8px" }}
        >
          <Close />
        </IconButton>
      </Toolbar>
      <Divider variant="middle" />
      <InformationBox
        variant="danger"
        title="ยกเลิกการจอง"
        description="โปรดทราบ พื้นที่นี้หากยกเลิกการจองแล้วจะไม่ได้รับเงินคืนในทุกๆกรณี"
        sx={{ m: 2 }}
      />
      <Typography textAlign="left" variant="display" ml={2} mt={3} mb={0.5}>
        ท่านต้องการยกเลิกการจองใช่หรือไม่?
      </Typography>
      <List sx={{ pb: 2 }}>
        {cancelOption.map((option) => (
          <>
            <ListItem
              button
              key={option.key}
              onClick={() => props.handleClick(option)}
            >
              <ListItemText primary={option.text} />
              <ChevronRight />
            </ListItem>
            <Divider variant="middle" />
          </>
        ))}
      </List>
    </Box>
  );
}
