import { LicenseInfo } from "@mui/x-license-pro";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Layout from "common/components/layout";
import tracker from "common/utils/openreplay.js";
import ModalProvider from "mui-modal-provider";
import { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "routes/Router";

import "antd/dist/antd.css";
import "semantic-ui-css/semantic.min.css";
import "./App.css";

tracker.start();

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO);

const checkUnauthorized = (error) => {
  const statusCode = error.response ? error.response.status : null;
  if (statusCode === 401) {
    localStorage.removeItem("token");
  }
};

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Layout>
          <ModalProvider>
            <Router />
          </ModalProvider>
        </Layout>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
