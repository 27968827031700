import { Grid, Typography } from "@mui/material";
import pdfFonts from "assets/custom-fonts.js";
import { MuiButton } from "common/components/button";
import { Item } from "common/components/form";
import { Footer } from "common/components/nav";
import Price from "common/components/price";
import { useSpaceInfo } from "common/hooks/use-query/space.hook";
import bookingStore from "common/stores/booking.store";
import spaceStore from "common/stores/space.store";
import _ from "lodash";
import PaymentSubmitLogic from "modules/space/booking/payment/payment.hook";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Bold.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
  // Kanit Font
  Kanit: {
    // 3. set Kanit font
    normal: "Kanit-Regular.ttf",
    bold: "Kanit-Medium.ttf",
    italics: "Kanit-Italic.ttf",
    bolditalics: "Kanit-MediumItalic.ttf",
  },
};

export default function FooterComponent(props) {
  return props?.sticky ? (
    <Footer disabledPaper sx={{ borderTop: 2, borderTopColor: "grey.200" }}>
      <PayloadComponent {...props} />
    </Footer>
  ) : (
    <PayloadComponent {...props} />
  );
}

function PayloadComponent(props) {
  const { paymentCondition } = PaymentSubmitLogic(props);
  return (
    <>
      <Summary {...props} />
      <Grid xs={12} item>
        {paymentCondition.isInstantPayment && <ButtonCondition1 {...props} />}
        {paymentCondition.isRequireApprove && <ButtonCondition2 {...props} />}
        {paymentCondition.isDirectLandLord && <ButtonCondition3 {...props} />}
      </Grid>
    </>
  );
}

function ButtonCondition1(props) {
  const { payment, handleSubmit } = PaymentSubmitLogic(props);

  return (
    <MuiButton
      fullWidth
      color="primary"
      variant="contained"
      loading={props.loading}
      onClick={handleSubmit}
      disabled={_.isNil(payment?.type)}
      sx={{ mb: 2 }}
      {...props}
    >
      ชำระเงิน
    </MuiButton>
  );
}

function ButtonCondition2(props) {
  return (
    <>
      <MuiButton
        fullWidth
        color="blue"
        variant="contained"
        loading={props.loading}
        onClick={() => {
          props.onClick();
          props.goToNamedStep("success");
        }}
        sx={{ mb: 2 }}
        {...props}
      >
        ยืนยันการจอง
      </MuiButton>
    </>
  );
}

function ButtonCondition3(props) {
  return (
    <>
      <MuiButton
        fullWidth
        color="blue"
        variant="contained"
        loading={props.loading}
        onClick={() => {
          props.onClick();
          props.goToNamedStep("success");
        }}
        sx={{ mb: 2 }}
        {...props}
      >
        ยืนยันการจอง
      </MuiButton>
    </>
  );
}

export function Summary(props) {
  const booking = bookingStore((e) => e.booking);
  const { isLoading: spaceIsLoading } = useSpaceInfo(booking?.space_id);
  const space = spaceStore((e) => e.space);
  return (
    <Grid
      container
      rowSpacing={1}
      sx={props.disabled ? { color: "grey.500" } : {}}
      pb={3}
    >
      <Item xs={12} bold fontSize={20}>
        ข้อมูลการชำระ
      </Item>
      {props?.bookingStatus === "approved" && (
        <Item fontSize={16} xs={12} right sx={{ pt: 2.5 }}>
          <Typography
            color="#18A0FB"
            fontSize={16}
            children="ใบยืนยันการจองพื้นที่"
            onClick={() => PrintPDF(booking, space)}
          />
        </Item>
      )}
      {!_.isEmpty(props.informationComponent) && (
        <Item xs={12} my={1}>
          {props.informationComponent}
        </Item>
      )}
      <Item xs={6} fontSize={{ xs: 16, md: 14 }}>
        จำนวนวันที่จอง
      </Item>
      <Item xs={6} fontSize={{ xs: 16, md: 14 }} right>
        {props.totalDays} วัน
      </Item>
      <Item xs={6} fontSize={{ xs: 16, md: 14 }}>
        ราคาต่อพื้นที่
      </Item>
      <Item xs={6} fontSize={{ xs: 16, md: 14 }} right>
        <Price value={props.boothPrice} /> บาท
      </Item>
      <Item xs={6} bold fontSize={{ xs: 16, md: 14 }}>
        ยอดสุทธิ
      </Item>
      <Item
        xs={6}
        bold={!props.disabled ?? true}
        right
        fontSize={{ xs: 20, md: 16 }}
        color={props.disabled ? "grey.500" : "primary.main"}
      >
        <Price value={props.totalPrice} /> บาท
      </Item>
    </Grid>
  );
}

async function PrintPDF(booking, space) {
  if (!_.isEmpty(space) && !_.isEmpty(booking)) {
    const businessType =
      (await !_.isEmpty(space?.business_type)) &&
      Object.keys(space?.business_type).join(", ");
    const amenity =
      (await !_.isEmpty(space?.amenity)) &&
      Object.keys(space?.amenity).join(", ");
    let boothArea = "-";
    if (!_.isEmpty(space?.booth_area)) {
      boothArea = `${space?.booth_area} ตรม.`;
      if (space?.booth_width && space?.booth_length) {
        boothArea = boothArea.concat(
          ` (${space?.booth_width}x${space?.booth_length} ม.)`
        );
      }
    } else {
      if (space?.booth_width && space?.booth_length) {
        boothArea = `${space?.booth_width}x${space?.booth_length} ม.`;
      }
    }
    const AREA_TYPE = {
      indoor: "ในห้าง",
      outdoor: "นอกห้าง",
      "": "",
    };
    const { booking_date_range_info, space_booth_price, booking_total_price } =
      {
        ...booking,
      };
    const { total_days } = { ...booking_date_range_info?.[0] };

    const numberWithCommas = (x) => {
      let value = x.toFixed(2).toLocaleString();
      if (x > 999) {
        value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return value;
    };

    const docDefinition = {
      info: {
        title: `ใบยืนยันการจองพื้นที่-${booking?.booking_number}`,
        author: "cp-origin",
      },
      content: [
        {
          stack: [
            { image: "logo", width: 120, height: 20, margin: [0, 0, 0, 0] },
          ],
          style: "logoStyle",
        },
        {
          columns: [
            {
              width: 350,
              text: [
                {
                  text: "ใบยืนยันการจอง\n",
                  alignment: "left",
                  fontSize: 12,
                  bold: true,
                },
                {
                  text: "กรุณาแสดงใบยืนยันการจองพื้นที่ในรูปแบบไฟล์อิเล็กทรอนิกส์หรือสําเนากระดาษเมื่อเข้าสู่พื้นที่\n",
                  alignment: "left",
                  fontSize: 9,
                },
              ],
            },
            {
              width: 140,
              margin: [0, 6, 0, 0],
              columns: [
                {
                  width: 70,
                  text: [
                    { text: "หมายเลขการจอง\n", bold: true, fontSize: 9 },
                    { text: `วันที่ออกใบจอง\n`, bold: true, fontSize: 9 },
                  ],
                },
                {
                  width: 70,
                  text: [
                    {
                      text: `${booking?.booking_number ?? "-"}\n`,
                      bold: true,
                      fontSize: 9,
                      alignment: "right",
                    },
                    {
                      text: `${moment().format("LL")}\n`,
                      bold: true,
                      fontSize: 9,
                      alignment: "right",
                    },
                  ],
                },
              ],
            },
          ],
          columnGap: 10,
          lineHeight: 1.5,
        },
        {
          margin: [0, 16],
          columns: [
            {
              width: 245,
              fontSize: 9,
              text: [
                { text: "CP ORIGIN COMPANY LIMITED\n", bold: true },
                { text: "\n", lineHeight: 0.3 },
                {
                  text: "101 Sukhumvit Road 5th Floor, Room 545 True Digital Park Pegasus Tower Phra Khanong, Bangkok Thailand 10260\n",
                },
                { text: "\n", lineHeight: 0.3 },
                {
                  text: "เบอร์โทร: 064-745-9955     อีเมล: originsme@cp-origin.com\n",
                },
              ],
            },
            {
              width: 245,
              fontSize: 9,
              text: [
                { text: "ผู้จอง\n", bold: true },
                { text: "\n", bold: true, lineHeight: 0.3 },
                `ชื่อผู้จอง: ${booking?.user_first_name ?? "-"} ${
                  booking?.user_last_name ?? "-"
                }\n`,
                `ชื่อร้าน: ${booking?.shop_name}\n`,
                `ที่อยู่ในการออกบิล:\n`,
                `${booking?.booking_billing_address ?? "-"}\n`,
                { text: "\n", bold: true, lineHeight: 0.3 },
                { text: "ข้อมูลร้านค้า\n", bold: true },
                `สินค้าและบริการ : ${booking?.shop_business_type ?? "-"}\n`,
                `ราคาสินค้าและบริการ : ${booking?.shop_min_price ?? ""}-${
                  booking?.shop_max_price ?? ""
                } บาท\n`,
              ],
            },
          ],
          columnGap: 10,
          lineHeight: 1,
        },
        {
          style: "tableExample",
          fontSize: 9,
          table: {
            widths: [30, 250, 65, 65, 90],
            headerRows: 1,
            body: [
              [
                {
                  fillColor: "#F9F9F9",
                  text: "ลำดับ",
                  alignment: "center",
                  bold: true,
                },
                {
                  fillColor: "#F9F9F9",
                  text: "รายการ",
                  alignment: "center",
                  bold: true,
                },
                {
                  fillColor: "#F9F9F9",
                  text: "จำนวนวันที่จอง",
                  alignment: "center",
                  bold: true,
                },
                {
                  fillColor: "#F9F9F9",
                  text: "ราคาต่อพื้นที่",
                  alignment: "center",
                  bold: true,
                },
                {
                  fillColor: "#F9F9F9",
                  text: "จำนวนเงิน",
                  alignment: "center",
                  bold: true,
                },
              ],
              [
                {
                  fillColor: "#F9F9F9",
                  text: "\n1",
                  alignment: "center",
                  bold: true,
                },
                {
                  fillColor: "#F9F9F9",
                  style: "tableExample",
                  table: {
                    widths: [100, 130],
                    body: [
                      [
                        {
                          border: undefined,
                          text: `\n${booking.mall_name}\n`,
                          fontSize: 9,
                        },
                        {
                          border: undefined,
                          text: "",
                        },
                      ],
                      [
                        {
                          border: undefined,
                          text: [
                            { text: `ช่วงวันที่จอง:\n`, fontSize: 8 },
                            { text: `จำนวนวันที่จอง:\n`, fontSize: 8 },
                            { text: `ราคาต่อพื้นที่:\n`, fontSize: 8 },
                          ],
                        },
                        {
                          border: undefined,
                          text: [
                            {
                              text: `${booking.booking_date_range_info.map(
                                (bd) => moment(bd.from_date).format("ll")
                              )} - ${booking.booking_date_range_info.map((bd) =>
                                moment(bd.to_date).format("ll")
                              )}\n`,
                              fontSize: 8,
                            },
                            { text: `${total_days ?? "-"} วัน\n`, fontSize: 8 },
                            {
                              text: `${
                                numberWithCommas(space_booth_price) ?? "-"
                              } บาท\n\n`,
                              fontSize: 8,
                              margin: [0, 0, 0, 8],
                            },
                          ],
                        },
                      ],
                      [
                        {
                          border: [false, true, false, false],
                          text: [
                            { text: `\n`, fontSize: 8 },
                            { text: `ชื่อพื้นที่:\n` },
                            { text: `ชั้นที่:\n` },
                            { text: `ประเภทสัญญา:\n` },
                            { text: `ขนาดพื้นที่:\n` },
                            { text: `เหมาะสำหรับ:\n` },
                            { text: `สิ่งอำนวยความสะดวก:\n\n\n` },
                          ],
                        },
                        {
                          border: [false, true, false, false],
                          text: [
                            { text: `\n`, fontSize: 8 },
                            { text: `${space?.name ?? "-"}\n` },
                            { text: `${space?.floor ?? "-"}\n` },
                            { text: `${space?.contract_type ?? "-"} \n` },
                            { text: `${boothArea ?? "-"} \n` },
                            {
                              text: `${
                                !_.isEmpty(businessType) ? businessType : "-"
                              }\n`,
                            },
                            {
                              text: `${
                                !_.isEmpty(amenity) ? amenity : "-"
                              }\n\n\n`,
                            },
                          ],
                        },
                      ],
                    ],
                  },
                  layout: {
                    defaultBorder: false,
                  },
                },
                {
                  fillColor: "#F9F9F9",
                  text: `\n${total_days ?? "-"}`,
                  alignment: "center",
                  bold: true,
                },
                {
                  fillColor: "#F9F9F9",
                  text: `\n${numberWithCommas(space_booth_price) ?? "-"}`,
                  alignment: "center",
                  bold: true,
                },
                {
                  fillColor: "#F9F9F9",
                  text: `\n${numberWithCommas(booking_total_price) ?? "-"}`,
                  alignment: "center",
                  bold: true,
                },
              ],
              [
                {
                  colSpan: 4,
                  margin: [0, 6, 0, 0],
                  text: [
                    { text: "มูลค่าก่อนรวมภาษี\n", fontSize: 9 },
                    { text: "ภาษีมูลค่าเพิ่ม (VAT)\n", fontSize: 9 },
                    { text: "ยอดรวม\n", fontSize: 12, bold: true },
                  ],
                  alignment: "right",
                  lineHeight: 1.3,
                },
                "",
                "",
                "",
                {
                  margin: [0, 6, 0, 0],
                  text: [
                    {
                      text: `${numberWithCommas(booking_total_price) ?? "-"}\n`,
                      fontSize: 9,
                    },
                    { text: "0.00\n", fontSize: 9 },
                    {
                      text: `${numberWithCommas(booking_total_price) ?? "-"}\n`,
                      fontSize: 12,
                      bold: true,
                    },
                  ],
                  alignment: "right",
                  lineHeight: 1.3,
                },
              ],
            ],
          },
        },
        {
          text: [
            { text: "\n" },
            { text: "รายละเอียดการชำระเงิน\n", bold: true, lineHeight: 1.3 },
            { text: "\n", bold: true, lineHeight: 0.2 },
            {
              text: "สามารถทำได้เมื่อได้รับการอนุมัติ: พื้นที่นี้ต้องได้รับการอนุมัติจากเจ้าของพื้นที่ก่อน ชำระเงินเมื่อได้รับการอนุมัติท่านจะได้รับการแจ้งเตือน\nการชำระเงินและสามารถเข้ามาชำระเงินที่หน้า “การจองของฉัน” ได้\n",
              lineHeight: 1,
            },
            { text: "\n", bold: true, lineHeight: 0.2 },
            {
              text: "ช่องทางการชำระเงิน: Truemoney Wallet, QR Code\n",
              lineHeight: 1.3,
            },
          ],
          fontSize: 9,
        },
        {
          text: [
            { text: "\n" },
            { text: "ข้อกำหนดและเงื่อนไข\n", bold: true, lineHeight: 1.3 },
            { text: "\n", bold: true, lineHeight: 0.2 },
            {
              text: "นโยบายการยกเลิกการจอง: การจองนี้ไม่สามารถขอเงินคืนและไม่สามารถแก้ไขหรือเปลี่ยนแปลงได้\nกรณีที่ท่านไม่เข้าสู่พื้นที่เช่าตามกําหนดท่านไม่สามารถขอรับเงินคืนได้\n",
              lineHeight: 1,
            },
          ],
          fontSize: 9,
          margin: [0, 6, 0, 0],
        },
      ],
      images: {
        logo: "https://storage.googleapis.com/storage.lotuss.originsme.com/statics/email-notification/logo-cp-origin.png",
      },
      defaultStyle: {
        font: "Kanit",
        lineHeight: 1,
      },
      styles: {
        logoStyle: {
          alignment: "right",
          margin: [0, 0, 0, 20],
        },
        bigger: {
          lineHeight: 1.5,
        },
      },
    };
    await pdfMake.createPdf(docDefinition).open();
  }
}
