import theme from "common/styles/ci";
import { useMutation } from "@tanstack/react-query";
import { updateUserInfo } from "services/user.service";
import SeedingModalTemplate from "./template";

export default function SeedingTermsAndConModal(props) {
  const { mutate } = useMutation({
    mutationKey: ["seedingTermsAndCon"],
    mutationFn: () =>
      updateUserInfo({
        seeding_terms_conditions: true,
      }),
  });

  function handleClick() {
    mutate();
    props.onClick();
  }

  return (
    <SeedingModalTemplate
      title="ข้อกำหนดและเงื่อนไข"
      body={theme["t&c_html"]}
      onClick={handleClick}
    />
  );
}
