import { Flag, ReportProblem } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { AppBar } from "common/components/nav";
import shopStore from "common/stores/shop.store";
import themeStore from "common/stores/theme.store";
import userStore from "common/stores/user.store";
import useVariant from "common/styles/variants";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchBar from "./search-bar";

const styleSchema = {
  show: {
    bgcolor: "primary.main",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
  },
  unshow: {
    bgcolor: "transparent",
    boxShadow: "0",
  },
};

export default function Header(props) {
  const user = userStore((e) => e.user);
  const { style } = useAppBarStyle();
  const variants = useVariant();
  const theme = themeStore((e) => e.theme);

  return (
    <>
      <AppBar
        to="/recruit"
        pb={0}
        menu
        disabledBack={props?.disabledBack ?? true}
        var
        sx={{
          color: "white",
          border: 0,
          ...style,
        }}
        {...props.AppBarProps}
      >
        {props.title ?? "หน้าแรก"}
      </AppBar>
      {!props.disabledProfile && (
        <Box
          sx={{
            background: {
              xs: variants["linearGradient"]?.background,
              md: "primary.main",
            },
            pt: { xs: 3, sm: 1.5, md: 0 },
            py: props.disabledSearchBar ? 2 : 0,
          }}
        >
          <Container
            sx={{
              backgroundPosition: "right",
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${theme?.transparent_logo})`,
            }}
          >
            <Grid container pt={5} pb={{ xs: 1, md: 4 }} direction="row">
              <Grid item sx={{ display: { xs: "none", sm: "block" } }}>
                <Avatar
                  src={theme?.avatar}
                  sx={{
                    bgcolor: "white",
                    p: 0.7,
                    width: { xs: 41, sm: 65, md: 90 },
                    height: { xs: 41, sm: 65, md: 90 },
                  }}
                />
              </Grid>
              <Grid item pl={{ xs: 2, md: 4 }}>
                <Grid container direction="column" sx={{ color: "white" }}>
                  <Typography fontSize={{ xs: 10, sm: 12, md: 14 }}>
                    ยินดีต้อนรับ
                  </Typography>
                  {!!user?.id ? <Auth /> : <Unauth />}
                </Grid>
              </Grid>
            </Grid>
            <Grid px={{ sm: 4, md: 8 }}>
              {props.SearchBarComponent ??
                (!props.disabledSearchBar && (
                  <SearchBar
                    placeholder="หาพื้นที่เช่า"
                    {...props.SearchBarProps}
                  />
                ))}
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
}

function Auth(props) {
  const user = userStore((e) => e.user);
  const primaryShop = shopStore((e) => e.primaryShop);
  const theme = themeStore((e) => e.theme);

  return (
    <>
      <Typography fontSize={{ xs: 20, md: 30 }} fontWeight="bold" mb={0.5}>
        คุณ {user.first_name}
      </Typography>
      {primaryShop?.name ? (
        <ShopExist />
      ) : (
        theme?.modules?.recruit && <ShopNotExist />
      )}
    </>
  );
}

function Unauth(props) {
  return (
    <>
      <Typography
        fontSize={{ xs: 20, sm: 25, md: 30 }}
        fontWeight="bold"
        mb={0.5}
      >
        ให้เราทำความรู้จักคุณ
      </Typography>
      <Link to="/register">
        <CustomButton
          variant="contained"
          // components={Link}
          // to="/register"
          sx={{
            textDecoration: "none",
            background: "#E7AD3F",
            ":hover": {
              color: "white",
              bgcolor: props?.color ?? "#E7AD3F",
            },
          }}
        >
          ลงทะเบียน
        </CustomButton>
      </Link>
    </>
  );
}

function ShopExist(props) {
  const primaryShop = shopStore((e) => e.primaryShop);
  return (
    <Grid item container mt={0.5}>
      <Flag sx={(theme) => ({ mr: 1, color: theme.palette.primary.light })} />
      <Typography fontSize={{ xs: 12, sm: 14, md: 16 }} width={250} noWrap>
        ร้าน {primaryShop?.name}
      </Typography>
    </Grid>
  );
}

function ShopNotExist(props) {
  const navigate = useNavigate();

  return (
    <CustomButton
      onClick={() => navigate("/recruit/add-shop")}
      color="lightBlue"
      variant="contained"
    >
      <ReportProblem sx={{ mr: 1, fontSize: 18 }} />
      <Typography fontSize={{ xs: 12, md: 12 }}>สร้างข้อมูลธุรกิจ</Typography>
    </CustomButton>
  );
}

function CustomButton(props) {
  return (
    <Grid item>
      <Button
        {...props}
        size="small"
        sx={{
          px: 2,
          height: 30,
          borderRadius: 2.5,
          fontSize: 12,
          color: "white",
          boxShadow: "0",
          ...props.sx,
        }}
      >
        {props.children}
      </Button>
    </Grid>
  );
}

function useAppBarStyle() {
  const [style, setStyle] = useState(styleSchema["unshow"]);

  function showAppBar() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const show = winScroll > 5;
    setStyle(styleSchema[show ? "show" : "unshow"]);
  }

  useEffect(() => {
    window.addEventListener("scroll", showAppBar);
    return () => window.removeEventListener("scroll", showAppBar);
  }, []);

  return { style };
}
