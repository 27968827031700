import { useMutation, useQuery } from "@tanstack/react-query";
import useSeedingAgreement from "common/modals/seeding/seeding.hook";
import userStore from "common/stores/user.store";
import tracker from "common/utils/openreplay.js";
import _ from "lodash";
import * as userService from "services/user.service";

const getToken = () => localStorage.getItem("token");

export const useUserQuery = (token) => {
  const [setUser, setIsAuthenticated] = userStore((e) => [
    e.setUser,
    e.setIsAuthenticated,
  ]);
  const { checkRequireAgreement } = useSeedingAgreement();

  return useQuery({
    queryKey: ["userInfo", token ?? getToken()],
    queryFn: () => userService.getUserInfo(),
    onSuccess: (user) => {
      setUser(user);
      startTrackOpenReplay(user);
      checkRequireAgreement(user);
      setIsAuthenticated(true);
    },
    onError: (error) => {
      setIsAuthenticated(false);
    },
  });
};

function startTrackOpenReplay(user) {
  tracker.setUserID(user?.email ?? user?.phone);
  if (!!user?.email) {
    tracker.setMetadata("phone", user?.phone);
  }
}

export const useMutateUser = (token) => {
  const { setUser, setIsAuthenticated } = userStore();

  return useMutation({
    mutationKey: ["userInfo", token ?? getToken()],
    mutationFn: () => userService.getUserInfo(),
    onSuccess: (user) => {
      setUser(user);
      tracker.setUserID(user?.email ?? user?.phone);
      if (!!user?.email) {
        tracker.setMetadata("phone", user?.phone);
      }
    },
    onError: (error) => {
      setIsAuthenticated(false);
    },
  });
};

export const useForgotPasswordQuery = () => {
  const mutationFunction = (phone) => userService.forgotPassword(phone);

  return useMutation(mutationFunction, {
    mutationKey: "forgotPassword",
    onSuccess: (data) => {
      if (!_.isEmpty(data)) {
        localStorage.setItem("encrypted_code", data.encrypted_code);
        localStorage.setItem("ref_code", data.ref_code);
        localStorage.setItem("resetpassword", data.phone);
        localStorage.setItem("has_password", data.has_password);
      }
    },
  });
};
