import React from "react";
import { Grid } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
// import { isMobile } from "common/utils/mediaQuery";
import DesktopHeader from "common/components/nav/header.desktop";
import DesktopFooter from "common/components/nav/footer.desktop";
import themeStore from "common/stores/theme.store";

export default function Layout() {
  const { modules } = themeStore((e) => e.theme);

  if (!modules?.recruit) {
    return <Navigate to="/" />;
  }

  return (
    <Grid container>
      <Grid container sx={{ display: { xs: "none", md: "block" } }}>
        <DesktopHeader />
      </Grid>
      <Grid pt={{ xs: 0, md: "66px" }} container>
        <Grid item xs={12}>
          <Outlet />
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "none", md: "block" } }}>
          <DesktopFooter />
        </Grid>
      </Grid>
    </Grid>
  );
}
