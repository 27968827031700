import loadable from "@loadable/component";

const BusinessMatchingLayout = loadable(() => import("modules/business-matching/layout"));

const BusinessMatchingForm = loadable(() => import("pages/business-matching/form"));
const BusinessMatchingStatus = loadable(() => import("pages/business-matching/status"));
const BusinessMatchingPreview = loadable(() => import("pages/business-matching/preview"));
const RegisteredBusinessMatchingDetail = loadable(() => import("pages/business-matching/status/registration-id"));

const basePath = "business-matching";

const businessMatchingRoute = ["/", basePath].map((path) => ({
  exact: true,
  path,
  element: <BusinessMatchingLayout />,
  children: [
    { exact: true, index: true, element: <BusinessMatchingPreview /> },
    { exact: true, path: `preview`, element: <BusinessMatchingPreview /> },
    { exact: true, path: "form", element: <BusinessMatchingForm /> }, //need auth
    { exact: true, path: "status", element: <BusinessMatchingStatus /> }, //need auth
    { exact: true, path: `status/:registrationId`, element: <RegisteredBusinessMatchingDetail /> }, //need auth
  ],
}));
export default businessMatchingRoute;
