import loadable from "@loadable/component";

import HomeScreen from "pages/space";
import Booking from "pages/space/booking";
import BookingPayment from "pages/space/booking/id";
import ContactLandlord from "pages/space/booking/id/contact";
import BookingDetail from "pages/space/booking/id/detail";

import PreviewPayment from "pages/space/booking/preview-payment/space-id";
import SuccessBooking from "pages/space/booking/success";
import CampaignScreen from "pages/space/campaign";
import MallExplorer from "pages/space/mall";
import SpaceScreen from "pages/space/mall/booth/space-id";
import SpaceTermsAndConditions from "pages/space/mall/booth/space-id/terms-and-conditions";
import MapExplorer from "pages/space/mall/explorer";
import MallDetail from "pages/space/mall/id";
import MallCampaignScreen from "pages/space/mallCampaign";
import TermsAndConditions from "pages/space/t&c/space-id";

// Setting

import SuccessCancel from "modules/space/booking/id/preview/support/cancel/success-cancel";

const SpaceLayout = loadable(() => import("modules/space/layout"));
const basePath = "space";

const spaceRoute = [
  {
    exact: true,
    path: basePath,
    element: <SpaceLayout />,
    children: [
      { exact: true, index: true, element: <HomeScreen /> },
      { exact: true, path: `home`, element: <HomeScreen /> },
      { exact: true, path: `terms-and-conditions/:spaceId`, element: <TermsAndConditions /> },
      { exact: true, path: `preview-payment/:spaceId`, element: <PreviewPayment /> },
      {
        exact: true,
        path: `booking`,
        desktop: true,
        children: [
          { exact: true, index: true, element: <Booking /> },
          { exact: true, path: `:bookingId`, element: <BookingPayment /> },
          { exact: true, path: `:bookingId/cancel/success`, element: <SuccessCancel /> },
          { exact: true, path: `:bookingId/detail`, element: <BookingDetail /> },
          { exact: true, path: `:bookingId/contact`, element: <ContactLandlord /> },
          { exact: true, path: `success`, element: <SuccessBooking /> },
        ],
      },
      {
        exact: true,
        path: `mall`,
        desktop: true,
        children: [
          { exact: true, index: true, element: <MallExplorer /> },
          { exact: true, path: `explorer`, element: <MapExplorer /> },
          { exact: true, path: `:mallId`, element: <MallDetail /> },
          { exact: true, path: `booth/:spaceId`, element: <SpaceScreen /> },
          { exact: true, path: `booth/:spaceId/terms-and-conditions`, element: <SpaceTermsAndConditions /> },
        ],
      },
      {
        exact: true,
        path: `campaign`,
        desktop: true,
        children: [
          { exact: true, path: `:groupId`, element: <CampaignScreen /> },
          { exact: true, path: `:groupId/mall/:mallId`, element: <MallCampaignScreen /> },
        ],
      },
    ],
  },
];

export default spaceRoute;
