import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Modal,
  Button,
  Divider,
  Grid,
  SwipeableDrawer,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close, InfoOutlined } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import spaceStore from "common/stores/space.store";
import bookingStore from "common/stores/booking.store";
import * as spaceService from "services/space.service";
import Item, { Price } from "./booth.component";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { isLaptopQuery } from "common/utils/mediaQuery";
import _ from "lodash";
import { DateRangePickerDay as MuiDateRangePickerDay } from "@mui/x-date-pickers-pro/DateRangePickerDay";
import { useMutation } from "@tanstack/react-query";

const DateRangePickerDay = styled(MuiDateRangePickerDay)(
  ({ theme, isHighlighting, isStartOfHighlighting, isEndOfHighlighting }) => ({
    // ...(isHighlighting && {
    //   borderRadius: 0,
    //   backgroundColor: "#dc1a178c",
    //   color: "white",
    //   "&:hover, &:focus": {
    //     backgroundColor: theme.palette.primary.dark,
    //   },
    // }),
    // ...(isStartOfHighlighting && {
    //   borderTopLeftRadius: "50%",
    //   borderBottomLeftRadius: "50%",
    // }),
    // ...(isEndOfHighlighting && {
    //   borderTopRightRadius: "50%",
    //   borderBottomRightRadius: "50%",
    // }),
  })
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",

  borderRadius: 3,
  boxShadow: 24,
  p: 2,
};

export default function Responsive(props) {
  const isMatch = isLaptopQuery();
  return (
    <>
      {isMatch ? (
        <Grid container sx={{ display: { xs: "none", md: "block" } }}>
          <Modal {...props}>
            <Box sx={style}>
              <Calendar device="desktop" onClose={props.onClose} />
            </Box>
          </Modal>
        </Grid>
      ) : (
        <Grid container sx={{ display: { xs: "block", md: "none" } }}>
          <SwipeableDrawer {...props} anchor="right">
            <Box width="100vw" pt={4}>
              <Calendar device="mobile" onClose={props.onClose} />
            </Box>
          </SwipeableDrawer>
        </Grid>
      )}
    </>
  );
}

export function Calendar(props) {
  const [value, setValue] = useState([null, null]);
  const [space, availableSpace] = spaceStore((e) => [
    e.space,
    e.availableSpace,
  ]);

  const availableDate = useMemo(() => transformDate(), [availableSpace]);

  function transformDate() {
    return _.fromPairs(availableSpace.map((v) => [v.date, true]));
  }

  function disabledDay(day) {
    const dateString = dayjs(day).format("YYYY-MM-DD");
    const match1 = dateUnavailable(dateString);
    const match2 = dateBetween(dateString);
    return match1 || match2;
  }

  function dateUnavailable(dateString) {
    return !(dateString in availableDate);
  }

  function dateBetween(dateString) {
    if (_.isNil(space?.min_booking_day) || _.isNil(value?.[0])) return false;

    return dayjs(dateString).isBetween(
      dayjs(value[0]),
      dayjs(value[0]).add(space?.min_booking_day - 1, "day")
    );
  }

  const renderWeekPickerDay = (date, dateRangePickerDayProps) => {
    return <DateRangePickerDay {...dateRangePickerDayProps} />;
  };

  return (
    <Grid container direction="column">
      <Grid
        item
        container
        justifyContent="space-between"
        itemAligns="center"
        pb={{ md: 4 }}
        pt={1}
      >
        <Typography fontSize={20} fontWeight="bold" pl={2}>
          คุณได้เลือกวันที่
        </Typography>
        <IconButton
          sx={{ position: "absolute", right: "10px" }}
          onClick={props.onClose}
        >
          <Close />
        </IconButton>
      </Grid>
      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ m: 3 }}>
          <StaticDateRangePicker
            displayStaticWrapperAs={props.device}
            value={value}
            disablePast
            disableOpenPicker
            minDate={
              space?.min_pre_booking_day
                ? dayjs().add(space?.min_pre_booking_day, "day")
                : undefined
            }
            maxDate={
              value?.[0] && space?.max_booking_day
                ? dayjs(value?.[0]).add(space?.max_booking_day, "day")
                : undefined
            }
            shouldDisableDate={disabledDay}
            renderDay={renderWeekPickerDay}
            closeOnSelect={false}
            componentsProps={{
              actionBar: {
                value: value,
                onClose: props.onClose,
              },
            }}
            components={{
              ActionBar: CustomActionBar,
            }}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </>
            )}
            // sx={{
            //   ".MuiDateRangePickerDay-dayInsideRangeInterval": {
            //     color: "white",
            //   },
            // }}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}

function CustomActionBar(props) {
  const { value, onClose } = props;
  const space = spaceStore((e) => e.space);
  const [period, addPeriod] = bookingStore((e) => [e.period, e.addPeriod]);
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState(null);

  const { mutate } = useMutation(
    () =>
      spaceService.validateAvailable({
        space_id: space?.id,
        from_date: value?.[0],
        to_date: value?.[1],
      }),
    {
      onSuccess: () => {
        addPeriod({
          from_date: dayjs(value?.[0]).format("YYYY-MM-DD"),
          to_date: dayjs(value?.[1]).format("YYYY-MM-DD"),
          total_days: dayjs(value?.[1]).diff(value?.[0], "day") + 1,
        });
        onClose();
      },
      onError: (err) => {
        setError(err?.message ?? "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
      },
    }
  );

  function handleClick() {
    mutate();
  }

  function checkDuplicatedPeriod() {
    const isMatch = _.some(period, {
      from_date: dayjs(value?.[0]).format("YYYY-MM-DD"),
      to_date: dayjs(value?.[1]).format("YYYY-MM-DD"),
    });

    if (isMatch) {
      setWarning(
        "รายการมีวันซ้ำกับช่วงเวลาที่เลือกก่อนหน้า หากต้องการดำเนินงานต่อ"
      );
      return;
    }

    setWarning(null);
  }

  useEffect(() => {
    checkDuplicatedPeriod();
  }, [value]);

  return (
    <Grid container direction="column" justifyContent="center" rowSpacing={2}>
      {!!space?.min_booking_day && (
        <>
          <BulletComponent>
            ขั้นต่ำ {space?.min_booking_day} วัน
          </BulletComponent>
          {error && <BulletComponent color="#DE0E0E">{error}</BulletComponent>}
          {warning && (
            <BulletComponent color="#EB983F">{warning}</BulletComponent>
          )}
          <Divider sx={{ mx: 4, pt: 2 }} />
        </>
      )}
      <Typography
        component={Grid}
        item
        fontSize={24}
        fontWeight="bold"
        textAlign="center"
      >
        <Price price={space?.booth_price} />
      </Typography>
      <Grid item px={{ xs: 4, md: 22 }}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          onClick={handleClick}
          disabled={_.isEmpty(value?.[0]) || _.isEmpty(value?.[1])}
        >
          เลือกวัน
        </Button>
      </Grid>
    </Grid>
  );
}

function BulletComponent(props) {
  const defaultColor = "grey.500";

  return (
    <Grid container item px={4} alignItems="center" direction="row">
      <InfoOutlined
        sx={{ fontSize: 24, color: props.color ?? defaultColor, mr: 1 }}
      />
      <Typography item fontSize={16} color={props.color ?? defaultColor}>
        {props.children}
      </Typography>
    </Grid>
  );
}
