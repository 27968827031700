import { ModalDrawer } from "common/components/modal";
import _ from "lodash";
import { useModal } from "mui-modal-provider";
import { useState } from "react";
import StepWizard from "react-step-wizard";
import SeedingConsentModal from "./consent";
import SeedingTermsAndConModal from "./t&c";

export default function useSeedingAgreement(user) {
  const { showModal } = useModal();

  function checkRequireAgreement(user) {
    const requireTermsAndCon = !user?.seeding_terms_conditions;
    const requireConsent = _.isNil(user?.allow_disclosure_agreement);

    if (requireTermsAndCon || requireConsent) {
      showModal(ModalStepWizard, {
        requireTermsAndCon,
        requireConsent,
      });
    }
  }

  return { checkRequireAgreement };
}

function ModalStepWizard(props) {
  const [stepWizardInstance, setStepWizardInstance] = useState({});

  function handleTermsAndConClick() {
    if (props.requireConsent) {
      return stepWizardInstance.nextStep();
    }
    props.onClose();
  }

  function handleConsentClick() {
    props.onClose();
  }

  return (
    <ModalDrawer
      {...props}
      width={{ md: "85vw", lg: "75vw" }}
      sx={{
        px: 1,
      }}
    >
      <StepWizard instance={setStepWizardInstance} transitions={{}}>
        {props.requireTermsAndCon && (
          <SeedingTermsAndConModal onClick={handleTermsAndConClick} />
        )}
        {props.requireConsent && (
          <SeedingConsentModal onClick={handleConsentClick} />
        )}
      </StepWizard>
    </ModalDrawer>
  );
}
