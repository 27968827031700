import { Box } from "@mui/material/";
import {
  usePrimaryShopQuery,
  useShopQuery,
  useUserQuery,
} from "common/hooks/use-query";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useLocalStorage from "use-local-storage";

export default function AppLayout(props) {
  const location = useLocation();
  const [token] = useLocalStorage("token", "");

  useUserQuery(token);
  useShopQuery();
  usePrimaryShopQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Box mx="auto">
      <Box sx={{ minHeight: "100vh" }}>{props.children}</Box>
    </Box>
  );
}
