import { Divider, Grid, TextField, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import bookingStore from "common/stores/booking.store";
import _ from "lodash";
import * as userService from "services/user.service";

export default function BillAddress(props) {
  const { data, isLoading } = useQuery(
    "userAddress",
    userService.getUserAddress
  );
  const { detail, street, district, sub_district, province, postcode } = {
    ...data?.[0],
  };
  const booking = bookingStore((e) => e.booking);

  function handleChange(e) {
    const value = e.target.value;
    if (value?.length > 1500) {
      props?.setErrorAddress(true);
      props?.setTextErrorAddress("กรอกที่อยู่ได้เพียง 1500 อักขระเท่านั้น");
    } else {
      props?.setErrorAddress(false);
      props?.setTextErrorAddress("");
    }
    props?.setAddress(e.target.value);
  }

  return (
    <>
      {props?.isInputBillingAddress ? (
        <>
          <Divider component={Grid} xs={12} sx={{ my: 3 }} />
          <Grid container rowSpacing={1}>
            <Typography fontSize={16} fontWeight="bold" mr={1}>
              ที่อยู่ในการออกบิล:
            </Typography>
            <Grid container mt={2}>
              <Grid item xs={12}>
                <TextField
                  id="my-input"
                  label="ที่อยู่"
                  aria-describedby="my-helper-text"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  error={props?.errorAddress}
                  helperText={props?.textErrorAddress}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        !_.isEmpty(booking?.booking_billing_address) && (
          <>
            <Divider component={Grid} xs={12} sx={{ my: 3 }} />
            <Grid container rowSpacing={1}>
              <Typography fontSize={16} fontWeight="bold" mr={1}>
                ที่อยู่ในการออกบิล:
              </Typography>
              <Typography fontSize={16}>
                {booking?.booking_billing_address}
              </Typography>
            </Grid>
          </>
        )
      )}
    </>
  );
}
