import { NumericFormat } from "react-number-format";

export default function Price(props) {
  return (
    <NumericFormat
      displayType="text"
      value={props.value}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
    />
  );
}
