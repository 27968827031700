import { useQuery } from "@tanstack/react-query";
import * as companyService from "services/company.service";
import userStore from "common/stores/user.store";

export const useCompany = () => {
  const isAuthenticated = userStore((e) => e.isAuthenticated);
  return useQuery({
    queryKey: ["getCompany"],
    queryFn: () => companyService.getCompany(),
    enabled: isAuthenticated,
  });
};
