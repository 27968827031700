import loadable from "@loadable/component";

const Campaign = loadable(() => import("pages/recruit/campaign"));
const Explorer = loadable(() => import("pages/recruit/campaign/explorer"));
const CampaignDetail = loadable(() => import("pages/recruit/campaign/detail"));
const MyPartner = loadable(() => import("pages/recruit/my-partner"));
const MyPartnerDetail = loadable(() => import("pages/recruit/my-partner/detail"));
const MyPartnerSuccess = loadable(() => import("pages/recruit/my-partner/success"));
const TermsAndConditions = loadable(() => import("pages/recruit/campaign/terms-and-conditions"));

const CampaigntLayout = loadable(() => import("modules/recruit/campaign/layout"));

const basePath = "recruit";

const recruitRoute = [
  {
    exact: true,
    path: `/${basePath}/campaign`,
    desktop: true,
    element: <CampaigntLayout title="สมัครโครงการ" />,
    children: [
      { exact: true, index: true, element: <Campaign /> },
      { exact: true, path: `explorer`, element: <Explorer /> },
      { exact: true, path: `:campaignID`, element: <CampaignDetail /> },
      { exact: true, path: `terms-and-conditions`, element: <TermsAndConditions /> },
      { exact: true, path: `my-partner`, element: <MyPartner /> },
      { exact: true, path: `my-partner/:dealNO`, element: <MyPartnerDetail /> },
      { exact: true, path: `my-partner/success`, element: <MyPartnerSuccess /> },
    ],
  },
];

export default recruitRoute;
