import { createTheme } from "@mui/material/styles";
import _ from "lodash";

const font = "Prompt, sans-serif";

const muiTheme = createTheme();

const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: { zIndex: "0 !important" },
        asterisk: { color: "red" },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: "bold",
          justifyContent: "flex-end",
          padding: "10px 3px",
          marginRight: "15px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { borderRadius: "10px", legend: { width: "auto" } },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: { borderRadius: "10px" },
      },
      variants: [
        {
          props: { semiWidth: true },
          style: {
            [muiTheme.breakpoints.up("xs")]: {
              width: "100%",
            },
            [muiTheme.breakpoints.up("md")]: {
              width: 320,
            },
          },
        },
        {
          props: { size: "semi-large" },
          style: {
            height: 45,
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        {
          props: { size: "large" },
          style: {
            height: 48,
            fontSize: 16,
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            borderWidth: 1.5,
          },
        },
        {
          props: { variant: "contained", size: "large" },
          style: {
            fontWeight: "bold",
          },
        },
      ],
    },
  },
  typography: {
    fontFamily: font,
    display: {
      fontSize: "16px",
      fontWeight: "bold",
      display: "block",
      lineHeight: 2,
    },
  },
  introText: {
    fontFamily: font,
    color: "white",
  },
  palette: {
    primary: {
      main: "#00b451",
      dark: "#008324",
      light: "#58e77f",
      contrastText: "white",
    },
    lightBlue: {
      main: "#5B97E5",
      dark: "#2D4B72",
      light: "#3F69A0",
      contrastText: "white",
    },
    line: {
      main: "#5AC463",
      dark: "#1f9336",
      light: "#8ef892",
      contrastText: "white",
    },
    red: {
      main: "#F01C31",
      dark: "#d11427",
      light: "#EDEDED",
      contrastText: "white",
    },
    blue: {
      main: "#4488E5",
      dark: "#2d5a96",
      light: "#EDEDED",
      contrastText: "white",
    },
    green: {
      main: "#55BBB4",
      dark: "#388c86",
      light: "#EDEDED",
      contrastText: "white",
    },
    yellow: {
      main: "#DEB047",
      dark: "#b18c38",
      light: "#EDEDED",
      contrastText: "white",
    },
  },
});

export default theme;
