import formStore from "common/stores/form.store";
import { useEffect, useRef, useState } from "react";
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";

export function ValidatorHook(props) {
  const ref = useRef();
  const [formInvalid, setFormInvalid] = useState(true);
  const [form, setForm] = formStore((e) => [e.form, e.setForm]);

  ValidatorForm.addValidationRule("isThai", (value) => {
    const isNotThai = /[^\u0E00-\u0E7F]/.test(value);
    const isOther = /[a-zA-Z0-9'^$&+,:;=?@#|'<>.^*()%!-]/.test(value);
    if (isOther || isNotThai) {
      return false;
    }
    return true;
  });

  async function isFormValid() {
    const fn = ref?.current?.isFormValid();
    const result = await fn;
    setFormInvalid(!result);
  }

  function setFormRef(e) {
    ref.current = e;
  }

  useEffect(() => {
    if ("isFormValid" in ref.current) isFormValid();
  }, [ref.current, form]);

  return {
    ValidatorForm,
    TextValidator,
    SelectValidator,
    setFormRef,
    formInvalid,
  };
}
