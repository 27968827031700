import loadable from "@loadable/component";
import { Navigate } from "react-router-dom";

//              SME              //
const Home = loadable(() => import("pages/recruit/index"));

// ETC
const WayToShortTerm = loadable(() => import("modules/recruit/etc/way-to-short-term"));
const FinishCreateShop = loadable(() => import("pages/recruit/register-finish"));
const CheckDocuments = loadable(() => import("modules/recruit/etc/check-documents"));
const FailedShop = loadable(() => import("pages/recruit/add-shop/failed"));

const SMECreateShop = loadable(() => import("pages/recruit/add-shop"));
const AddShopBusinessMatching = loadable(() => import("pages/recruit/add-shop/campaign"));
const AddShopShortTermSpace = loadable(() => import("pages/recruit/add-shop/short-term/space"));

const SuccessShop = loadable(() => import("modules/recruit/add-shop/success-shop"));
const ContentItem = loadable(() => import("pages/recruit/content"));

// Business Matching
const RecruitLayout = loadable(() => import("modules/recruit/layout"));

const basePath = "recruit";

const recruitRoute = [
  {
    exact: true,
    path: `/${basePath}`,
    element: <RecruitLayout />,
    children: [
      { exact: true, index: true, element: <Home /> },
      { exact: true, path: "home", element: <Home /> },
    ],
  },

  //อื่น ๆ

  { exact: true, path: `/${basePath}/content/:contentName`, element: <ContentItem /> },
  {
    exact: true,
    path: `/${basePath}/preview`,
    children: [
      { exact: true, path: `business-matching`, element: <Navigate to="/business-matching/preview" /> }, //legacy
    ],
  },

  //  {/* ETC */}
  { exact: true, path: `/${basePath}/way-to-short-term-rental`, element: <WayToShortTerm /> },
  { exact: true, path: `/${basePath}/register-finish`, element: <FinishCreateShop /> },
  { exact: true, path: `/${basePath}/check-documents`, element: <CheckDocuments /> },
  { exact: true, path: `/${basePath}/success`, element: <SuccessShop /> },

  {
    exact: true,
    path: `/${basePath}/add-shop`,
    element: <RecruitLayout />,
    children: [
      { exact: true, index: true, element: <SMECreateShop /> },
      { exact: true, path: `:shopId`, element: <SMECreateShop /> },
      { exact: true, path: `campaign`, element: <AddShopBusinessMatching /> },
      { exact: true, path: `campaign/:shopId`, element: <AddShopBusinessMatching /> },
      { exact: true, path: `short-term/space`, element: <AddShopShortTermSpace /> },
      { exact: true, path: `short-term/space/:shopId`, element: <AddShopShortTermSpace /> },
      { exact: true, path: `failed`, element: <FailedShop /> },
    ],
  },
];

export default recruitRoute;
