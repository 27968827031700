import { useRoutes } from "react-router-dom";
import authRoute from "./auth";
import businessMatchingRoute from "./business-matching";
import devRoute from "./dev";
import lineRoute from "./line";
import publicRoute from "./public";
import recruitRoute from "./recruit";
import settingsRoute from "./settings";
import spaceRoute from "./space";

export default function Router() {
  const routing = useRoutes(routes);
  return routing;
}

//prettier-ignore
const routes = [
  ...spaceRoute, 
  ...recruitRoute, 
  ...settingsRoute, 
  ...publicRoute, 
  ...businessMatchingRoute,
  ...lineRoute, 
  ...devRoute, 
  ...authRoute]
