import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, Divider, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Footer } from "common/components/nav";
import { ModalLogin } from "common/components/modal";
import userStore from "common/stores/user.store";
import _ from "lodash";
import { isMobileApp } from "services/mobileapp";
import { BsLine } from "react-icons/bs";
import { useLineUrlQuery } from "common/hooks/use-query/line.hook";
import { useState } from "react";

export const MuiButton = (props) => {
  const navigate = useNavigate();
  const user = userStore((e) => e.user);
  const { showLoginModal } = ModalLogin({
    onClick: () =>
      localStorage.setItem("from_service", props?.unauthTo ?? props.to),
  });

  const style = {
    fontSize: "16px",
    fontWeight: props.variant === "text" ? "normal" : "bold",
    ...props.sx,
  };

  function handleOnClick() {
    if (props.checkAuth && _.isEmpty(user)) {
      showLoginModal({
        afterLogin: props.afterLogin,
        afterRegister: props.afterRegister,
      });
      return;
    }
    props.to ? navigate(props.to) : props.onClick && props.onClick();
  }

  const buttonProps = {
    type: props.type,
    color: props.color,
    variant: props.variant ?? "contained",
    disabled: props.disabled ?? false,
    semiWidth: props.semiWidth,
    size: props.size ?? "large",
    fullWidth: props.fullWidth,
    onClick: handleOnClick,
    loading: props.loading,
    sx: style,
  };

  return "loading" in props ? (
    <MuiLoadingButton {...buttonProps} children={props.children} />
  ) : (
    <BaseButton {...buttonProps} children={props.children} />
  );
};

export function BaseButton(props) {
  return (
    <Button {...props}>
      {props.children ?? `เพิ่มข้อมูล และอัพโหลดเอกสาร`}
    </Button>
  );
}

export function MuiLoadingButton(props) {
  return (
    <LoadingButton {...props}>
      {props.children ?? `เพิ่มข้อมูล และอัพโหลดเอกสาร`}
    </LoadingButton>
  );
}

export function FooterButton(props) {
  return props.sticky ? (
    <Footer>
      <Grid {...props.stickyProps}>
        <Content {...props} />
      </Grid>
    </Footer>
  ) : (
    <Grid sx={{ mt: props.marginTop ?? "30px", mb: props?.mb ?? 3 }}>
      <Content {...props} />
    </Grid>
  );
}

function Content(props) {
  return (
    <Grid container spacing={2} textAlign="center">
      <Grid item xs={12}>
        <MuiButton
          variant="contained"
          {...props}
          onClick={() => (props.onClick ? props.onClick() : props.nextStep())}
          sx={{
            ...props?.sx,
          }}
        >
          {props.children}
        </MuiButton>
      </Grid>
      {props.backButton && (
        <Grid item xs={12}>
          <MuiButton
            fullWidth={props.fullWidth}
            semiWidth={props.semiWidth}
            variant="text"
            sx={{
              color: "#727272",
              ...props?.backButtonSx,
            }}
            onClick={() =>
              props.backOnClick ? props.backOnClick() : props.previousStep()
            }
          >
            {props.backButton === true
              ? props.label ?? `ย้อนกลับ`
              : props.backButton}
          </MuiButton>
        </Grid>
      )}

      {props.customBackButton}
    </Grid>
  );
}

export function CheckAuth(props) {
  const user = userStore((state) => state.user);
  const { showLoginModal } = ModalLogin({
    onClick: () =>
      localStorage.setItem("from_service", props?.unauthTo ?? props.to),
  });

  if (props.checkAuth && _.isEmpty(user)) {
    showLoginModal();
    return;
  }
}

export function LineLogin(props) {
  const renderLineLoginButton = props.webAppOnly ? !isMobileApp : true;
  const [redirecting, setRedirecting] = useState(false);
  const { data: lineUrl, isLoading } = useLineUrlQuery();

  function registerWithLine() {
    setRedirecting(true);
    try {
      window.location.href = lineUrl;
    } catch (err) {
      setRedirecting(false);
    }
  }

  return (
    renderLineLoginButton && (
      <>
        <LoadingButton
          variant="outlined"
          size="large"
          onClick={registerWithLine}
          color="line"
          fullWidth
          startIcon={<BsLine size={30} />}
          loading={isLoading || redirecting}
          sx={{
            fontWeight: "bold",
            textTransform: "inherit",
          }}
        >
          เชื่อมต่อด้วย LINE Account
        </LoadingButton>

        <Divider sx={{ my: 3.5 }}>
          <Typography
            sx={{
              color: "gray",
              fontSize: "15px",
            }}
          >
            หรือลงทะเบียนด้วย
          </Typography>
        </Divider>
      </>
    )
  );
}
