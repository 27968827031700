import { useMutation } from "@tanstack/react-query";
import {
  useMutatePrimaryShop,
  useMutateShop,
  useMutateUser,
} from "common/hooks/use-query";
import shopStore from "common/stores/shop.store";
import userStore from "common/stores/user.store";
import * as authenService from "services/authen.service";

export function useMutateLogin() {
  const { mutateAsync: mutateUser } = useMutateUser();
  const { mutateAsync: mutateShop } = useMutateShop();
  const { mutateAsync: mutatePrimaryShop } = useMutatePrimaryShop();

  return useMutation({
    mutationKey: ["mutateLogin"],
    mutationFn: (payload) => authenService.login(payload),
    onSuccess: async (data) => {
      mutateShop();
      await mutateUser();
      await mutatePrimaryShop();
    },
    onError: (err) => {
      console.error("get login: ", err);
      throw err;
    },
  });
}

export function useMutateLogout() {
  const clearUser = userStore((e) => e.clearUser);
  const clearShops = shopStore((e) => e.clearShops);
  return useMutation({
    mutationKey: "logout",
    mutationFn: authenService.logout,
    onSuccess: () => {
      clearUser();
      clearShops();
      localStorage.clear();
    },
  });
}
