import { useState } from "react";
import ErrorModal from "common/modals/error";
import userStore from "common/stores/user.store";
import _ from "lodash";
import "modules/space/style.css";
import { useModal } from "mui-modal-provider";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Modal } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { isLaptopQuery } from "common/utils/mediaQuery";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

const drawerBleeding = 56;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 807,
  bgcolor: "background.paper",
  textAlign: "center",
  borderRadius: 3,
  py: 4,
};

export function ModalDrawer(props) {
  const isLaptop = isLaptopQuery();

  return (
    <>
      {isLaptop ? (
        <Modal {...props}>
          <Box
            sx={{
              ...style,
              ...(props.width && { width: props.width }),
              px: 6,
              ...props.sx,
            }}
          >
            {props.children}
          </Box>
        </Modal>
      ) : (
        <Drawer anchor="bottom" {...props}>
          <Box
            mx="1"
            role="presentation"
            height={1000}
            mt={2}
            sx={{ px: { xs: 2, md: 0 } }}
          >
            {props.children}
          </Box>
        </Drawer>
      )}
    </>
  );
}

export function HalfDrawer(props) {
  const [open, setOpen] = useState(true);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      variant="permanent"
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        ".MuiPaper-root": {
          zIndex: 1,
          borderRadius: 3,
          pb: { xs: "40px", sm: 0 },
          boxShadow: "0px -14px 21px -1px rbgba(6, 25, 56, 0.08)",
          ...props.sx,
        },
      }}
    >
      {props.children}
    </Drawer>
  );
}

export function ModalLogin(props) {
  const navigate = useNavigate();
  const { user } = userStore();
  const { showModal } = useModal();
  const location = useLocation();

  function checkAuth(payload) {
    if (_.isEmpty(user)) {
      showLoginModal(payload);
      return false;
    }
    return true;
  }

  function showLoginModal(payload) {
    const modal = showModal(ErrorModal, {
      title: "ไม่พบโปรไฟล์ของคุณ",
      body: "เพื่อให้สามารถเข้าใช้บริการของเราได้ โปรดทำการ “เข้าสู่ระบบ” หรือ ”ลงทะเบียน” เพื่อสร้างโปรไฟล์ใหม่เพื่อรับสิทธิพิเศษมากมาย",
      button: "เข้าสู่ระบบ",
      onClick: () => {
        localStorage.setItem(
          "from_service",
          payload?.afterLogin ?? location.pathname
        );
        navigate("/auth/login");
        modal.hide();
      },
      secondButton: "ลงทะเบียน",
      secondOnClick: () => {
        localStorage.setItem(
          "from_service",
          payload?.afterRegister ?? location.pathname
        );
        navigate("/register");
        modal.hide();
      },
    });
  }

  return { checkAuth, showLoginModal };
}
