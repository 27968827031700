import { Container, Typography } from "@mui/material";
import Body from "common/pages/terms-and-conditions/body";
import Footer from "common/pages/terms-and-conditions/footer";

export default function SeedingModalTemplate(props) {
  return (
    <Container sx={{ pb: { xs: "80px", sm: "0px" }, px: { xs: 0, sm: 2 } }}>
      <Typography
        fontWeight="bold"
        fontSize={{ xs: 20, sm: 30 }}
        textAlign="center"
        mb={4}
      >
        {props.title}
      </Typography>

      <Body
        height="30vh"
        text={props.body}
        bgcolor="#FBFBFB"
        disabledTitle
        disabledFooter
        textProps={{
          textAlign: "left",
        }}
      />
      <Footer
        semiWidth
        choice={props.choice}
        bgcolor="#fff"
        label="ไว้คราวหลัง"
        onClick={(e) => props.onClick(e)}
        backOnClick={() => props.onClose()}
        backButton
        width="auto"
      />
    </Container>
  );
}
