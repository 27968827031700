import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import MobileAppBar from "./header.mobile";
import { TempDesktopHeader } from "common/components/nav/header.desktop";
import _ from "lodash";

export default function AppBar(props) {
  return (
    <>
      <Grid sx={{ display: { xs: "block", md: "none" } }}>
        <MobileAppBar {...props} />
      </Grid>

      <Grid container sx={{ display: { xs: "none", md: "block" } }}>
        <HandleTabletAppBar {...props} />
      </Grid>
    </>
  );
}

function HandleTabletAppBar(props) {
  if (!_.isEmpty(props.children) && props.tempAppBar) {
    return <TempDesktopHeader {...props} />;
  }

  return <></>;
}
