import { Navigate } from "react-router-dom";
import NotFound from "common/components/NotFound";
import loadable from "@loadable/component";
import Layout from "modules/recruit/layout";
import DefaultPage from "pages/default";
// Register
const SmeRegister = loadable(() => import("pages/recruit/register"));
const SmeRegisterLine = loadable(() => import("pages/recruit/register/line"));

// Setting
const ProfileTermsAndCon = loadable(() => import("pages/recruit/profile/terms-and-conditions"));
const SettingContactus = loadable(() => import("modules/settings/contactus"));
//FAQ
const HowTo = loadable(() => import("pages/recruit/how-to"));
const General = loadable(() => import("pages/recruit/how-to/general"));
const Space = loadable(() => import("pages/recruit/how-to/space"));
const CampaignFAQ = loadable(() => import("pages/recruit/how-to/campaign"));
const BusinessMatchingFAQ = loadable(() => import("pages/recruit/how-to/business_matching"));

const publicRoute = [
  { path: "*", element: <DefaultPage /> },
  { exact: true, path: "/404", element: <NotFound /> },
  { exact: true, path: `/register`, element: <SmeRegister /> },
  { exact: true, path: `/register/line`, element: <SmeRegisterLine /> },
  {
    exact: true,

    path: `/`,
    element: <Layout />,
    children: [
      // { index: true, exact: true, element: <Navigate to={defaultRoute} /> },
      { exact: true, path: `/contactus`, element: <SettingContactus /> },
      { exact: true, path: `/terms-and-condition`, element: <ProfileTermsAndCon /> },
      {
        exact: true,
        path: "how-to",
        element: <HowTo />,
        children: [
          { exact: true, index: true, element: <General /> },
          { exact: true, path: "general", element: <General /> },
          { exact: true, path: "space", element: <Space /> },
          { exact: true, path: "campaign", element: <CampaignFAQ /> },
          { exact: true, path: "business-matching", element: <BusinessMatchingFAQ /> },
        ],
      },
    ],
  },
];

export default publicRoute;
