import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import {
  FacebookOutlined as FacebookOutlinedIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
} from "@mui/icons-material";
import { FaTiktok } from "react-icons/fa";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import OriginLabelLogo from "assets/img/origin-label-logo.png";
import { Link } from "react-router-dom";
import { ReactComponent as LineLogo } from "assets/img/main-page/lineLogo.svg";
import themeStore from "common/stores/theme.store";

import "./index.css";

export default function DesktopFooter(props) {
  const styleFooter = {
    mt: props?.mt ?? 8,
    pt: 3,
    pb: 6,
  };

  const theme = themeStore((e) => e.theme);

  return (
    <Grid
      sx={{
        bgcolor: "#fff",
        px: "24px",
      }}
      item
    >
      <Container sx={{ ...styleFooter }}>
        <Grid container>
          <Grid item xs={6}>
            <Contact />
          </Grid>
          <Grid item xs={6}>
            {theme?.portal && <Portal />}
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Container sx={{ pt: 3, pb: 5 }}>
        <Grid item xs={12}>
          <Typography fontSize={14} fontWeight="bold">
            ©{theme.year} All Rights Reserved by {theme.name}.Co.,Ltd
          </Typography>
        </Grid>
      </Container>
    </Grid>
  );
}

function Portal() {
  const portals = [
    { title: "หน้าแรก", url: "/recruit" },
    { title: "จับคู่ค้าธุรกิจ", url: "/business-matching/preview" },
    { title: "แหล่งความรู้ SME", url: "" },
    { title: "เช่าพื้นที่ขายของ", url: "/space" },
    { title: "พัฒนาธุรกิจ", url: "/recruit" },
    { title: "สมัครโครงการ", url: "/recruit/campaign" },
    { title: "ลงทุนทางธุรกิจ", url: "" },
  ];

  return (
    <Grid container>
      {portals.map((portal) => (
        <Grid key={portal.title} item xs={4} mb={3}>
          <Typography
            variant="body2"
            component={Link}
            to={portal.url}
            color="black"
          >
            {portal.title}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

export function Contact(props) {
  const theme = themeStore((e) => e.theme);
  const contacts = [
    {
      key: "facebook",
      icon: <FacebookOutlinedIcon />,
      to: "https://www.facebook.com/Cpseeding/",
    },
    {
      key: "instagram",
      icon: <InstagramIcon />,
      to: "https://www.instagram.com/cpsse_seeding/",
    },
    {
      key: "tiktok",
      icon: <FaTiktok />,
      to: "https://www.tiktok.com/@cpseeding",
    },
    {
      key: "youtube",
      icon: <YouTubeIcon />,
      to: "https://www.youtube.com/@cpssechannnel",
    },
    // {
    //   key: "line",
    //   icon: LineLogo,
    //   to: "https://lin.ee/FsIqhnL",
    // },
  ];

  return (
    <Grid container>
      {!props.disabledLogo && <Image src={theme.logo} height="61px" />}
      <Grid container mt={3} columnSpacing={{ xs: 2, md: 5 }}>
        {contacts.map((contact) => (
          <Grid item key={contact.key}>
            {contact.key === "line" ? (
              <a
                key={contact.key}
                href={contact.to}
                target="_blank"
                rel="noreferrer"
              >
                <LineLogo className="line" />
              </a>
            ) : (
              <Avatar
                component="a"
                href={contact.to}
                key={contact.key}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  height: 33,
                  width: 33,
                  bgcolor: "#000000",
                  ":hover": {
                    color: "primary.main",
                  },
                }}
              >
                {contact.icon}
              </Avatar>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
