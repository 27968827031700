import loadable from "@loadable/component";
import { Navigate } from "react-router-dom";

const ResetPassword = loadable(() => import("pages/settings/reset-password"));

// Setting Shop
const ShopsProfile = loadable(() => import("pages/settings/store"));
const ShopProfile = loadable(() => import("pages/settings/store/shop-id"));

// Change password
const ChangePassword = loadable(() => import("modules/settings/password/change-password"));
const CreatePassword = loadable(() => import("modules/settings/password/create-password"));

const UpdateSuccess = loadable(() => import("modules/settings/update-success"));

const SettingProfile = loadable(() => import("modules/settings/setting-profile"));
const SettingProfilePersonalInfo = loadable(() => import("modules/settings/profile-personal-info"));

const LayoutProfile = loadable(() => import("modules/recruit/layout-profile"));

const basePath = "settings";

const protectedRoute = [
  {
    exact: true,
    path: basePath,
    element: <LayoutProfile title="ร้านค้า" />,
    children: [
      {
        exact: true,
        path: `store`,
        children: [
          { exact: true, index: true, element: <ShopsProfile /> },
          { exact: true, path: `:shopId`, element: <ShopProfile /> },
        ],
      },
      {
        exact: true,
        path: `password`,
        children: [{ exact: true, path: "change", element: <ChangePassword /> }],
      },
      {
        exact: true,
        path: `profile`,
        children: [
          { exact: true, index: true, element: <SettingProfile /> },
          { exact: true, path: `personal`, element: <SettingProfilePersonalInfo /> },
        ],
      },
    ],
  },

  { exact: true, path: `/${basePath}/password/create`, element: <CreatePassword tempAppBar /> },
];

const spaceRoute = [
  ...protectedRoute,
  {
    exact: true,
    path: `/${basePath}/password`,
    children: [{ exact: true, path: "reset", element: <ResetPassword /> }],
  },
  { exact: true, path: `/${basePath}/profile/update/success`, element: <UpdateSuccess /> },
];

export default spaceRoute;
