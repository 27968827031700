import create from "zustand";
import ciTheme from "common/styles/ci";

const themeStore = create((set) => ({
  theme: ciTheme,
  setTheme: (theme) => set({ theme }),
  setMaxWidth: (maxWidth) => set({ maxWidth }),
  desktopRoute: [],
  setDesktopRoute: (desktopRoute) => set({ desktopRoute }),
}));

export default themeStore;
